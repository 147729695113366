export enum UserCategory {
    Preroll = 'Preroll',
    Flowers = 'Flowers',
    Edibles = 'Edibles',
    Carts = 'Carts',
    Concentrates = 'Concentrates',
    Disposable = 'Disposable',
    Other = 'Other',
}

export const CategoryStockImages = {
    [UserCategory.Preroll]: 'https://img.gobrandica.com/product_stock/preroll_stock.png',
    [UserCategory.Flowers]: undefined,
    [UserCategory.Edibles]: undefined,
    [UserCategory.Carts]: undefined,
    [UserCategory.Concentrates]: undefined,
    [UserCategory.Disposable]: undefined,
    [UserCategory.Other]: undefined
}