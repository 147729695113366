class FetchApi {
    private baseUrl: string;

    baseHeaders: Record<string, string> = {
        'Content-Type': 'application/json',
        'Accept': '*/*',
    }

    constructor(baseUrl: string, token?:string) {
        this.baseUrl = baseUrl;

        if (token) {
            this.baseHeaders['authorization'] = `Bearer ${token}`;
        }
    }

    // Method for GET requests
    async get(endpoint: string, headers: Record<string, string> = {}): Promise<any> {
        try {
            const response = await fetch(`${this.baseUrl}${endpoint}`, {
                method: 'GET',
                headers: {
                    ...this.baseHeaders,
                    ...headers,
                },
            });
            return await this.handleResponse(response);
        } catch (error) {
            console.error('Fetch GET Error:', error);
            throw error;
        }
    }

    // Method for POST requests
    async post(endpoint: string, data: any, headers: Record<string, string> = {}): Promise<any> {
        try {
            const response = await fetch(`${this.baseUrl}${endpoint}`, {
                method: 'POST',
                headers: {
                    ...this.baseHeaders,
                    ...headers,
                },
                body: JSON.stringify(data),
            });
            return await this.handleResponse(response);
        } catch (error) {
            console.error('Fetch POST Error:', error);
            throw error;
        }
    }

    // Method for PUT requests
    async put(endpoint: string, data: any, headers: Record<string, string> = {}): Promise<any> {
        try {
            const response = await fetch(`${this.baseUrl}${endpoint}`, {
                method: 'PUT',
                headers: {
                    ...this.baseHeaders,
                    ...headers,
                },
                body: JSON.stringify(data),
            });
            return await this.handleResponse(response);
        } catch (error) {
            console.error('Fetch PUT Error:', error);
            throw error;
        }
    }

    // Method for DELETE requests
    async delete(endpoint: string, headers: Record<string, string> = {}): Promise<any> {
        try {
            const response = await fetch(`${this.baseUrl}${endpoint}`, {
                method: 'DELETE',
                headers: {
                    ...this.baseHeaders,
                    ...headers,
                },
            });
            return await this.handleResponse(response);
        } catch (error) {
            console.error('Fetch DELETE Error:', error);
            throw error;
        }
    }

    // Helper method to handle responses
    private async handleResponse(response: Response): Promise<any> {
        if (!response.ok) {
            const errorResponse = await response.json();
            throw new Error(`Error: ${response.status} - ${JSON.stringify(errorResponse)}`);
        }
        return await response.json();
    }
}

export default FetchApi;