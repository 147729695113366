import { Action, ThunkAction } from "@reduxjs/toolkit";
import { AuthService } from "../../services/AuthService";
import FetchApi from "../../services/fetchApi";
import { FETCH_LOCATIONS_FAILURE, FETCH_LOCATIONS_START, FETCH_LOCATIONS_SUCCESS, SET_SELECTED_LOCATION, FETCH_LOCATIONS_PRODUCTS_FAILURE, FETCH_LOCATIONS_PRODUCTS_START, FETCH_LOCATIONS_PRODUCTS_SUCCESS, UpdateLocationProductInput, UPDATE_LOCATION_PRODUCT_START, UPDATE_LOCATION_PRODUCT_FAILURE, UPDATE_LOCATION_PRODUCT_SUCCESS, SET_QUERY, RESYNC_LOCATIONS_PRODUCTS_START, RESYNC_LOCATIONS_PRODUCTS_SUCCESS, RESYNC_LOCATIONS_PRODUCTS_FAILURE } from "../types/locations";
import { MultiLocalStorageManager } from "../../utils/useMultiLocalStorage";

export const FetchMyLocations = (storeId: string, localStorage: MultiLocalStorageManager): ThunkAction<void, any, unknown, Action<string>> => async (dispatch) => {
    try {
        dispatch({ type: FETCH_LOCATIONS_START});
        const authService = new AuthService(localStorage);
        const token = authService.getToken();
        if(!token) {
            return;
        }
        const fetchApi = new FetchApi(process.env.REACT_APP_API_URL || 'http://localhost:3000/api', token)

        const response = await fetchApi.get(`/store/${storeId}/location`);
        dispatch({ type: FETCH_LOCATIONS_SUCCESS, payload: response.data });
    } catch(err: any) {
        dispatch({type: FETCH_LOCATIONS_FAILURE, payload: err.message})
    }
}

export const SetSelectedLocation = (locationId: string): ThunkAction<void, any, unknown, Action<string>> => (dispatch) => {
        dispatch({ type: SET_SELECTED_LOCATION, payload: locationId});
}

export const FinishResync = (): ThunkAction<void, any, unknown, Action<string>> => (dispatch) => {
    dispatch({ type: RESYNC_LOCATIONS_PRODUCTS_SUCCESS, payload: {}});
}

export const SetQuery = (query: string): ThunkAction<void, any, unknown, Action<string>> => (dispatch) => {
    dispatch({ type: SET_QUERY, payload: query});
}

export const LocationSyncProducts = (storeId: string, locationId: string, localStorage: MultiLocalStorageManager): ThunkAction<void, any, unknown, Action<string>> => async (dispatch) => {
    try {
        const authService = new AuthService(localStorage);
        const token = authService.getToken();
        if(!token) {
            return;
        }
        const fetchApi = new FetchApi(process.env.REACT_APP_API_URL || 'http://localhost:3000/api', token)
        await fetchApi.post(`/store/${storeId}/location/${locationId}/products/sync`, {});
        dispatch({ type: RESYNC_LOCATIONS_PRODUCTS_START });
    } catch(err: any) {
        dispatch({type: RESYNC_LOCATIONS_PRODUCTS_FAILURE, payload: err.message})
    }
}

export const FetchLocationsProducts = (storeId: string, locationId: string, localStorage: MultiLocalStorageManager, page: number = 1, perPage: number = 20, lastEditedProductId: string | null = null): ThunkAction<void, any, unknown, Action<string>> => async (dispatch) => {
    try {
        dispatch({ type: FETCH_LOCATIONS_PRODUCTS_START});
        const authService = new AuthService(localStorage);
        const token = authService.getToken();
        if(!token) {
            return;
        }
        const fetchApi = new FetchApi(process.env.REACT_APP_API_URL || 'http://localhost:3000/api', token)
        const response = await fetchApi.get(`/store/${storeId}/location/${locationId}/products?lastEvaluatedKey=${page}&perPage=${perPage}` + (lastEditedProductId ? `&lastEditedProductId=${lastEditedProductId}` : ''));
        dispatch({ type: FETCH_LOCATIONS_PRODUCTS_SUCCESS, payload: {products: response.data.products, totalPages: response.data.totalPages, facets: response.data.facets} });
    } catch(err: any) {
        dispatch({type: FETCH_LOCATIONS_PRODUCTS_FAILURE, payload: err.message})
    }
}

export const SearchLocationsProducts = (storeId: string, locationId: string, query: string, localStorage: MultiLocalStorageManager, page: number = 1, perPage: number = 20, params: any = {}, lastEditedProductId: string | null = null): ThunkAction<void, any, unknown, Action<string>> => async (dispatch) => {
    try {
        dispatch({ type: FETCH_LOCATIONS_PRODUCTS_START});
        const authService = new AuthService(localStorage);
        const token = authService.getToken();
        if(!token) {
            return;
        }
        const fetchApi = new FetchApi(process.env.REACT_APP_API_URL || 'http://localhost:3000/api', token)
        const queryString = Object.entries(params).map(([key, value]) => `${key === 'userCategory' ? 'category' : key}=${value}`).join('&');
        let shouldSendFacets = false;
        // if no brand is specified do not send facets
        if(params.brand && !params.userCategory) {
            shouldSendFacets = true;
        }

        console.log({shouldSendFacets})

        
        const response = await fetchApi.post(`/store/${storeId}/location/${locationId}/products/search` + `?lastEvaluatedKey=${page}&perPage=${perPage}&` + queryString + (lastEditedProductId ? `&lastEditedProductId=${lastEditedProductId}` : ''), {query});
        dispatch({ type: FETCH_LOCATIONS_PRODUCTS_SUCCESS, payload: {products: response.data.products, totalPages: response.data.totalPages, facets: shouldSendFacets ? response.data.facets : undefined} });
    } catch(err: any) {
        dispatch({type: FETCH_LOCATIONS_PRODUCTS_FAILURE, payload: err.message})
    }
}

export const UpdateLocationProduct = (storeId: string, locationTag: string, productId: string, body: UpdateLocationProductInput, localStorage: MultiLocalStorageManager): ThunkAction<void, any, unknown, Action<string>> => async (dispatch) => {
    try {
        dispatch({ type: UPDATE_LOCATION_PRODUCT_START});
        const authService = new AuthService(localStorage);
        const token = authService.getToken();
        if(!token) {
            return;
        }
        const fetchApi = new FetchApi(process.env.REACT_APP_API_URL || 'http://localhost:3000/api', token)
        const response = await fetchApi.put(`/store/${storeId}/location/${locationTag}/product/${productId}`, body);
        dispatch({ type: UPDATE_LOCATION_PRODUCT_SUCCESS, payload: {...body, productId }});
    } catch(err: any) {
        dispatch({type: UPDATE_LOCATION_PRODUCT_FAILURE, payload: err.message})
    }
}
