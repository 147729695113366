import { 
    Card, 
    CardContent,
    CardMedia, 
    Typography, 
    Box,
    Chip,
    Stack,
    Divider,
    Paper,
    useTheme,
    IconButton,
    styled
} from '@mui/material';
import { Edit, CloudUpload, PowerSettingsNew } from '@mui/icons-material';
import { useState, useCallback, useRef, useEffect } from 'react';
import EditProductModal from './EditProductModal';
import { CategoryStockImages, UserCategory } from '../../types';
import ImageCropperModal from './ImageCropperModal';
import { UpdateLocationProduct } from '../../redux/actions/locations';
import { useAppDispatch } from '../../redux/hooks/hooks';
import { MultiLocalStorageManager } from '../../utils/useMultiLocalStorage';

interface PricePoint {
    price: number;
    quantity: number;
    unit: string;
}

interface ProductProps {
    availableQuantity: number;
    barcode_ids: string[];
    brand: string;
    cbd_concentration: string;
    name: string;
    product_id: string;
    med_price_points: PricePoint[];
    rec_price_points: PricePoint[];
    thc_concentration: string;
    userCategory: string;
    strain: string | null;
    images?: string[];
    description?: string;
    availableOnline?: boolean;
    onEdit?: (productId: string, values: { name: string; userCategory: UserCategory; description: string; availableOnline?: boolean }) => void;
    getS3UploadUrl: (imageId: string, imageType: string) => void;
    signedUploadUrl: string;
    selectedStoreId: string;
    selectedLocationId: string;
    onImageChange?: (productId: string, imageUrl: string) => void;
    localStorage: MultiLocalStorageManager;
}

const DropZoneOverlay = styled(Box)(({ theme }) => ({
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#fff',
    opacity: 0,
    transition: 'opacity 0.2s ease-in-out',
    '&:hover': {
        opacity: 1,
    },
    cursor: 'pointer',
}));

export default function ProductComponent(props: ProductProps) {
    const { 
        name,
        brand,
        availableQuantity,
        thc_concentration,
        cbd_concentration,
        product_id,
        userCategory,
        strain,
        med_price_points,
        rec_price_points,
        images,
        description,
        availableOnline,
        onEdit,
        getS3UploadUrl,
        signedUploadUrl,
        selectedStoreId,
        selectedLocationId,
        onImageChange,
    } = props;

    const theme = useTheme();
    const placeholderImage = CategoryStockImages[userCategory as UserCategory] || "https://placehold.co/500x500/EEE/31343C";

    const [editModalOpen, setEditModalOpen] = useState(false);
    const toggleEditModal = () => setEditModalOpen(!editModalOpen);

    const onEditWrapper = (productId: string, values: { name: string; userCategory: UserCategory; description: string; availableOnline?: boolean; brand?: string }) => {
        onEdit?.(productId, values);
        toggleEditModal();
    };

    const [isDragging, setIsDragging] = useState(false);
    const [selectedFile, setSelectedFile] = useState<File | null>(null);

    const fileInputRef = useRef<HTMLInputElement>(null);
    const timestampRef = useRef<number | null>(null);

    const [cropperOpen, setCropperOpen] = useState(false);
    const [fileToEdit, setFileToEdit] = useState<File | null>(null);

    const [isUploading, setIsUploading] = useState(false);

    const dispatch = useAppDispatch();

    const [imageUrl, setImageUrl] = useState<string>(images?.[0] || placeholderImage);

    useEffect(() => {
        if (images?.[0]) {
            setImageUrl(images[0]);
        }
    }, [images]);

    const handleDragEnter = useCallback((e: React.DragEvent) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragging(true);
    }, []);

    const handleDragLeave = useCallback((e: React.DragEvent) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragging(false);
    }, []);

    const handleDragOver = useCallback((e: React.DragEvent) => {
        e.preventDefault();
        e.stopPropagation();
    }, []);

    const handleDrop = useCallback((e: React.DragEvent) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragging(false);

        const files = Array.from(e.dataTransfer.files);
        if (files.length > 0) {
            const file = files[0];
            if (file.type.startsWith('image/')) {
                setFileToEdit(file);
                setCropperOpen(true);
            }
        }
    }, []);

    const handleFileSelect = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files;
        if (files && files.length > 0) {
            const file = files[0];
            if (file.type.startsWith('image/')) {
                setFileToEdit(file);
                setCropperOpen(true);
            }
        }
    }, []);

    const handleImageClick = useCallback((e: React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        fileInputRef.current?.click();
    }, []);

    const handleCropComplete = (croppedBlob: Blob) => {
        if(isUploading) return;
        const timestamp = Date.now();
        timestampRef.current = timestamp;
        const fileType = croppedBlob.type.split('/')[1];  // Get 'png' or 'jpeg' from mime type
        const imageKey = `${selectedStoreId}-${selectedLocationId}-${product_id}-image-${timestamp}`;
        const file = new File([croppedBlob], `${imageKey}.${fileType}`, { type: croppedBlob.type });
        setSelectedFile(file);
        setIsUploading(true);
        getS3UploadUrl(imageKey, file.type);
    };

    const formatDescription = (description: string, maxLength: number = 100) => {
        try {
            const content = JSON.parse(description);
            const text = content.content
                .map((node: any) => node.content?.map((c: any) => c.text).join(''))
                .join(' ');

            return text.length > maxLength 
                ? `${text.substring(0, maxLength)}...` 
                : text;
        } catch {
            return description.length > maxLength 
                ? `${description.substring(0, maxLength)}...` 
                : description;
        }
    };

    const handleAvailableOnlineToggle = useCallback((e: React.MouseEvent) => {
        e.stopPropagation();
        if (onEdit) {
            onEdit(product_id, {
                name,
                userCategory: userCategory as UserCategory,
                description: description || '',
                availableOnline: !availableOnline
            });
        }
    }, [onEdit, product_id, name, userCategory, description, availableOnline]);

    useEffect(() => {
        if (selectedFile && signedUploadUrl && isUploading) {
            const currentTimestamp = timestampRef.current;
            uploadImage()
                .then(() => {
                    if (currentTimestamp === timestampRef.current) {
                        // Use the selectedFile.name which already contains the correct timestamp and file type
                        const newImageUrl = `${process.env.REACT_APP_S3_URL}/${selectedStoreId}/${selectedFile.name}`;
                        setImageUrl(newImageUrl);
                        
                        dispatch(UpdateLocationProduct(
                            selectedStoreId,
                            selectedLocationId,
                            product_id,
                            {
                                images: [newImageUrl]
                            },
                            props.localStorage
                        ));

                        onImageChange?.(product_id, newImageUrl);
                    }
                    setIsUploading(false);
                    timestampRef.current = null;
                    setSelectedFile(null);
                })
                .catch((error) => {
                    console.error('Error uploading image:', error);
                    setIsUploading(false);
                    timestampRef.current = null;
                    setSelectedFile(null);
                });
        }
    }, [signedUploadUrl, selectedFile, isUploading, selectedStoreId, selectedLocationId, product_id, dispatch, onImageChange]);

    const uploadImage = async () => {
        if (!signedUploadUrl || !selectedFile) return;
        
        await fetch(signedUploadUrl, {
            method: 'PUT',
            body: selectedFile,
        });
    };

    return (
        <>
            <Card sx={{ 
                backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.05)' : 'rgba(0, 0, 0, 0.05)',
                '&:hover': {
                    boxShadow: 6,
                    transform: 'scale(1.02)',
                    transition: 'transform 0.2s ease-in-out'
                },
                position: 'relative'
            }}>
                <Box
                    sx={{ position: 'relative', cursor: 'pointer' }}
                    onDragEnter={handleDragEnter}
                    onDragLeave={handleDragLeave}
                    onDragOver={handleDragOver}
                    onDrop={handleDrop}
                    onClick={handleImageClick}
                >
                    <CardMedia
                        component="img"
                        height="320"
                        image={selectedFile ? URL.createObjectURL(selectedFile) : imageUrl}
                        alt={name}
                        sx={{ 
                            objectFit: 'cover',
                            transition: 'filter 0.3s ease-in-out',
                            '&:hover': {
                                filter: 'grayscale(0%)',
                            }
                        }}
                    />
                    <DropZoneOverlay 
                        sx={{ 
                            opacity: isDragging ? 1 : undefined,
                            '&:hover': {
                                opacity: 1
                            }
                        }}
                    >
                        <CloudUpload sx={{ fontSize: 40, mb: 1 }} />
                        <Typography variant="body1">
                            Drop image here or click to replace
                        </Typography>
                        <input
                            ref={fileInputRef}
                            type="file"
                            accept="image/*"
                            onChange={handleFileSelect}
                            onClick={(e) => e.stopPropagation()}
                            style={{ display: 'none' }}
                        />
                    </DropZoneOverlay>
                </Box>
                <CardContent>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                        <Typography variant="h6" gutterBottom noWrap>
                            {name}
                        </Typography>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            {onEdit && (
                                <>
                                    <IconButton 
                                        onClick={handleAvailableOnlineToggle} 
                                        size="small"
                                        sx={{ 
                                            mr: 1,
                                            color: availableOnline ? 'success.main' : 'error.main',
                                            '&:hover': {
                                                backgroundColor: availableOnline ? 
                                                    'success.light' : 'error.light',
                                                opacity: 0.8
                                            }
                                        }}
                                    >
                                        <PowerSettingsNew />
                                    </IconButton>
                                    <IconButton onClick={toggleEditModal} size="small">
                                        <Edit />
                                    </IconButton>
                                </>
                            )}
                        </Box>
                    </Box>
                    
                    <Typography variant="subtitle1" color="text.secondary" gutterBottom>
                        {brand || 'Unknown'}
                    </Typography>

                    <Stack direction="row" spacing={1} mb={2}>
                        <Chip 
                            label={`THC: ${Number(thc_concentration).toFixed(1)}%`}
                            size="small"
                            color="primary"
                        />
                        <Chip 
                            label={`CBD: ${Number(cbd_concentration).toFixed(1)}%`}
                            size="small"
                            color="secondary"
                        />
                    </Stack>

                    <Box sx={{ 
                        display: 'flex', 
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        mb: 2
                    }}>
                        <Paper elevation={1} sx={{ 
                            p: 1, 
                            backgroundColor: theme.palette.mode === 'dark' 
                                ? 'rgba(25, 118, 210, 0.12)' 
                                : '#e3f2fd'
                        }}>
                            <Typography variant="body2">
                                Qty: {availableQuantity}
                            </Typography>
                        </Paper>
                        <Paper elevation={1} sx={{ 
                            p: 1, 
                            backgroundColor: theme.palette.mode === 'dark'
                                ? 'rgba(46, 125, 50, 0.12)'
                                : '#e8f5e9'
                        }}>
                            <Typography variant="body2">
                                Category: {userCategory}
                            </Typography>
                        </Paper>
                    </Box>

                    {strain && (
                        <Typography variant="body2" color="text.secondary" gutterBottom>
                            Strain: {strain}
                        </Typography>
                    )}

                    <Divider sx={{ 
                        my: 1,
                        borderColor: theme.palette.mode === 'dark' 
                            ? 'rgba(255, 255, 255, 0.12)' 
                            : 'rgba(0, 0, 0, 0.12)'
                    }} />

                    <Box sx={{ mt: 1 }}>
                        {med_price_points.length > 0 && (
                            <Typography variant="body2" color="text.secondary">
                                Medical Price: ${med_price_points[0].price}/{med_price_points[0].unit}
                            </Typography>
                        )}
                        {rec_price_points.length > 0 && (
                            <Typography variant="body2" color="text.secondary">
                                Recreational Price: ${rec_price_points[0].price}/{rec_price_points[0].unit}
                            </Typography>
                        )}
                    </Box>

                    <Typography variant="caption" color="text.secondary" sx={{ mt: 1, display: 'block' }}>
                        Product ID: {product_id}
                    </Typography>

                    {(
                        <Typography 
                            variant="body2" 
                            color="text.secondary" 
                            sx={{ mt: 1, height: 60, overflow: 'none' }}
                            dangerouslySetInnerHTML={{ 
                                __html: formatDescription((description !== '' ? description : '') || '')
                            }}
                        />
                    )}
                </CardContent>

                {onEdit && (
                    <EditProductModal
                        open={editModalOpen}
                        toggleOpen={toggleEditModal}
                        productId={product_id}
                        initialValues={{
                            name,
                            userCategory: userCategory as UserCategory,
                            description,
                            availableOnline,
                            brand
                        }}
                        onSubmit={onEditWrapper}
                    />
                )}
            </Card>

            {fileToEdit && (
                <ImageCropperModal
                    open={cropperOpen}
                    onClose={() => {
                        setCropperOpen(false);
                        setFileToEdit(null);
                    }}
                    file={fileToEdit}
                    onCropComplete={handleCropComplete}
                />
            )}
        </>
    );
} 