import React, { useEffect } from 'react';
import MuiDrawer from '@mui/material/Drawer';
import { styled, Theme, CSSObject } from '@mui/material/styles';
import { Box, IconButton, MenuItem, Select, SelectChangeEvent, Typography } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../redux/hooks/hooks';
import { Article, PermMedia, TextFields, Inventory } from '@mui/icons-material';
import { SetSelectedLocation } from '../../redux/actions/locations';
import { ThunkDispatch } from '@reduxjs/toolkit';

const openedMixin = (theme: Theme): CSSObject => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
    [theme.breakpoints.down('sm')]: {
      width: '100vw',
      zIndex: 9999
    }
  });

  const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
      width: `calc(${theme.spacing(8)} + 1px)`,
    },
    [theme.breakpoints.down('sm')]: {
      width: '0'
    }
  });

const drawerWidth = 240;
const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme }) => ({
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap',
      boxSizing: 'border-box',
      variants: [
        {
          props: ({ open }) => open,
          style: {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
          },
        },
        {
          props: ({ open }) => !open,
          style: {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
          },
        },
      ],
    }),
  );

  const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  }));

export default function DashboardMenu({open, toggleOpen, theme}: {open: boolean, toggleOpen: () => void, theme: Theme}) {
    const navigate = useNavigate();
    const { stores, selectedStoreId } = useAppSelector(state => state.storesState);
    const selectedStore = stores?.find(store => store.storeId === selectedStoreId);

    const { locations, selectedLocationId } = useAppSelector(state => state.locationsState);
    const selectedLocation = locations?.find(location => location.sortKey.split('#')[1] === selectedLocationId);
    const dispatch: ThunkDispatch<any, any, any> = useAppDispatch();

    useEffect(() => {
      if(locations.length > 0 && !selectedLocationId) {
        dispatch(SetSelectedLocation(locations[0].sortKey.split('#')[1]))
      }
    }, [locations])
    const handleChangeLocation = (event: SelectChangeEvent) => {
      const locationId = event.target.value;
      dispatch(SetSelectedLocation(locationId));
    };
  return (
    <Drawer variant="permanent" open={open}>
    <DrawerHeader>
      {
        open && selectedStore && (
          <Box
            style={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
              justifyContent: 'center',
              flexGrow: 1,
            }}
          >
            <Typography variant="subtitle1" component="p" style={{color: 'lightgray', paddingBottom: 0}}>
              Managing
            </Typography>
            <Typography variant="h6" component="h6" style={{ paddingTop: 0, fontWeight: 'bold'}}>
              {selectedStore.name}
            </Typography>
          </Box>

        )
      }
      <IconButton onClick={toggleOpen}>
        {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
      </IconButton>
    </DrawerHeader>
    <Divider />
    <List>
        <ListItem disablePadding sx={{ display: 'none' }}>
            <ListItemButton
                onClick={() => navigate('/text-assets')}
                sx={[
                {
                    minHeight: 48,
                    px: 2.5,
                },
                open
                    ? {
                        justifyContent: 'initial',
                    }
                    : {
                        justifyContent: 'center',
                    },
                ]}
            >
                <ListItemIcon
                sx={[
                    {
                    minWidth: 0,
                    justifyContent: 'center',
                    },
                    open
                    ? {
                        mr: 3,
                        }
                    : {
                        mr: 'auto',
                        },
                ]}
                >
                {<TextFields />}
                </ListItemIcon>
                <ListItemText
                primary={"Text Assets"}
                sx={[
                    open
                    ? {
                        opacity: 1,
                        }
                    : {
                        opacity: 0,
                        },
                ]}
                />
            </ListItemButton>
        </ListItem>
        <ListItem disablePadding sx={{ display: 'none' }}>
            <ListItemButton
                onClick={() => navigate('/image-assets')}
                sx={[
                {
                    minHeight: 48,
                    px: 2.5,
                },
                open
                    ? {
                        justifyContent: 'initial',
                    }
                    : {
                        justifyContent: 'center',
                    },
                ]}
            >
                <ListItemIcon
                sx={[
                    {
                    minWidth: 0,
                    justifyContent: 'center',
                    },
                    open
                    ? {
                        mr: 3,
                        }
                    : {
                        mr: 'auto',
                        },
                ]}
                >
                {<PermMedia />}
                </ListItemIcon>
                <ListItemText
                primary={"Image Assets"}
                sx={[
                    open
                    ? {
                        opacity: 1,
                        }
                    : {
                        opacity: 0,
                        },
                ]}
                />
            </ListItemButton>
        </ListItem>
        <ListItem disablePadding sx={{ display: 'none' }}>
            <ListItemButton
                onClick={() => navigate('/blog-posts')}
                sx={[
                {
                    minHeight: 48,
                    px: 2.5,
                },
                open
                    ? {
                        justifyContent: 'initial',
                    }
                    : {
                        justifyContent: 'center',
                    },
                ]}
            >
                <ListItemIcon
                sx={[
                    {
                    minWidth: 0,
                    justifyContent: 'center',
                    },
                    open
                    ? {
                        mr: 3,
                        }
                    : {
                        mr: 'auto',
                        },
                ]}
                >
                {<Article />}
                </ListItemIcon>
                <ListItemText
                primary={"Blog Posts"}
                sx={[
                    open
                    ? {
                        opacity: 1,
                        }
                    : {
                        opacity: 0,
                        },
                ]}
                />
            </ListItemButton>
        </ListItem>
        <ListItem disablePadding sx={{ display: 'block' }}>
        {
                stores && stores.length > 0 && open &&
                <>
                  {open && (
                    <Typography
                      variant="subtitle2"
                      sx={{
                        pl: 2,
                        pt: 2,
                        pb: 1,
                        color: 'text.secondary'
                      }}
                    >
                      Manage Location
                    </Typography>
                  )}
                  <Select
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      maxHeight: '50px',
                      mx: 2,
                      width: 'calc(100% - 32px)'
                    }}
                    value={selectedLocationId || ''}
                    onChange={handleChangeLocation}
                  >
                    {
                      locations.map(loc => {
                        return (
                          <MenuItem key={loc.sortKey} value={loc.sortKey.split('#')[1]}>
                            {/* <StoreLinkComponent key={store.storeId} id={store.storeId} name={store.name} createdAt={store.createdAt} /> */}
                            {loc.sortKey.split('#')[1]}
                          </MenuItem>
                        );
                      })
                    }
                  </Select>
                </>

              }
        </ListItem>
        {selectedLocationId && (
          <ListItem disablePadding sx={{ display: 'block' }}>
              <ListItemButton
                  onClick={() => navigate('/products')}
                  sx={[
                  {
                      minHeight: 48,
                      px: 2.5,
                  },
                  open
                      ? {
                          justifyContent: 'initial',
                      }
                      : {
                          justifyContent: 'center',
                      },
                  ]}
              >
                  <ListItemIcon
                  sx={[
                      {
                      minWidth: 0,
                      justifyContent: 'center',
                      },
                      open
                      ? {
                          mr: 3,
                          }
                      : {
                          mr: 'auto',
                          },
                  ]}
                  >
                  {<Inventory />}
                  </ListItemIcon>
                  <ListItemText
                  primary={"Products"}
                  sx={[
                      open
                      ? {
                          opacity: 1,
                          }
                      : {
                          opacity: 0,
                          },
                  ]}
                  />
              </ListItemButton>
          </ListItem>
        )}
    </List>
  </Drawer>
  );
}